// src/context/TranslationContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

interface TranslationContextProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  t: (key: string) => string;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(
  undefined
);

interface TranslationProviderProps {
  children: ReactNode;
}

interface Translations {
  [language: string]: {
    [key: string]: string;
  };
}
export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState("pt");
  const [translations, setTranslations] = useState<Translations>({});

  const getTranslations = async () => {
    await fetch("./translations.json").then((response) => {
      response
        .json()
        .then((config) => {
          setTranslations(config);
        })
        .catch((err) => console.error("I cant load translations"));
    });
  };

  useEffect(() => {
    getTranslations();
  }, []);

  const t = (key: string) => translations[language][key] || key;

  return Object.keys(translations).length > 0 ? (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  ) : null;
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};
