import React, { useEffect, useState } from "react";

import "./App.css";

import { Header, Form } from "./components";
import { trackView } from "./analytics/utils";

function App() {
  const [config, setConfig] = useState("");
  useEffect(() => {
    trackView("View Portal");
  }, []);

  const getConfig = async () => {
    await fetch("./config.json").then((response) => {
      response
        .json()
        .then((config) => {
          setConfig(config.api);
        })
        .catch((err) => console.error("I cant load configs"));
    });
  };

  getConfig();

  return (
    <div className="app">
      <Header />

      {!!config ? (
        <div className="app-container">
          <Form config={config} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100vh",
          }}
        >
          <h1>Error geting configs</h1>
        </div>
      )}
    </div>
  );
}

export default App;
