import React from "react";
import "./style.css";

import logo from "../../assets/logo_vodafone.png";
import { useTranslation } from "../../providers/transltations.provider";

const Header = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
) => {
  const { t } = useTranslation();

  return (
    <header className="topbar" {...props}>
      <img className="logo" src={logo} alt="logo vodafone"></img>
      <h1 className="portal-title">{t("header_title")}</h1>
    </header>
  );
};

export default Header;
