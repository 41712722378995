const APP_NAME = "Portal de Remoção de Conta Vodafone";

export const trackView = (track: string, context?: object) => {
  // @ts-ignore
  window.utag?.view({
    app_name: APP_NAME,
    tealium_event_type: "view",
    track_type: "view",
    tealium_event: "view",
    action: track,
    ...context,
  });
};

export const trackEvent = (track: string, context?: object) => {
  // @ts-ignore
  window.utag?.link({
    app_name: APP_NAME,
    track_type: "event",
    tealium_event_type: "link",
    tealium_event: "link",
    action: track,
    ...context,
  });
};
