import React, { useEffect, useState } from "react";
import S from "./style.module.css";

//https://github.com/armenstepanyan/react-google-recaptcha3#readme
import ReactRecaptcha3 from "react-google-recaptcha3";
import chevron from "../../assets/chevron.png";
import { useTranslation } from "../../providers/transltations.provider";

import { trackEvent } from "../../analytics/utils";
import ToasterWrapper from "../Toaster/Toaster";
import toast from "react-hot-toast";

const Form = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { config: string }
) => {
  const { t } = useTranslation();
  const [submitValid, setSubmitValid] = useState({
    validEmail: false,
    validMsisdn: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const isSubmitEnabled = Object.values(submitValid).includes(false);

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_CAPTCHA_KEY).then(
      (status: string) => {
        // status: success/error
        // success - script is loaded and greaptcha is ready
        // error - script is not loaded
        console.log(status);
      }
    );
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    ReactRecaptcha3.getToken().then(
      (token: string) => {
        const formElement = e.target as HTMLFormElement;
        const isValid = formElement.checkValidity();

        if (isValid) {
          const dataObject = new FormData(formElement);

          const raw = JSON.stringify(Object.fromEntries(dataObject));

          const myHeaders = new Headers();
          myHeaders.append("x-mcare-client", "mcare");
          myHeaders.append("Content-Type", "application/json");

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
          };
          setIsLoading(true);
          fetch(`${props.config}`, requestOptions)
            .then((res) => {
              return res.json();
            })
            .then((resJson) => {
              setIsLoading(false);
              if (resJson.statusCode === "0")
                toast.success(t("toast_success_delete_account"));
              else throw new Error("Error on call");
              console.log(resJson);
            })
            .catch((err) => {
              setIsLoading(false);
              toast.error(t("toast_error_delete_account"));
            });

          trackEvent("Click Delete Account", {
            context_email: dataObject.get("email"),
          });
        }
      },
      (error: string) => {
        // handle error here
        console.log(error);
      }
    );
  };

  // if (!config) {
  //   return <h1>Error loading page!</h1>;
  // }

  return (
    <div className={S.card} {...props}>
      <ToasterWrapper />
      <form className={S.formContainer} onSubmit={handleSubmit}>
        <h1 className={S.title}>{t("form_title")} </h1>
        <p className={S.label}>{t("form_gen_code_subtitle")}</p>
        <details className={S.warningSfid}>
          <summary>
            {t("warning_title")}
            <span>
              <img className={S.chevron_icon} src={chevron} alt="chevron"></img>
            </span>
          </summary>
          <p>{t("warning_before_continue")}</p>
          <p>{t("warning_before_continue_item1")}</p>
          <p>{t("warning_before_continue_item2")}</p>
          <p>{t("warning_before_continue_item3")}</p>
          <p>{t("warning_before_continue_item4")}</p>
          <p>{t("warning_before_continue_bottom")}</p>
        </details>
        <div className={S.inputContainer}>
          <input
            className={S.input}
            name="email"
            placeholder="o seu email"
            type="email"
            pattern="(^[.a-zA-Z0-9_]+[@]{1}[\-a-z0-9]+[\.][a-z]+$)"
            required
            onChange={(e) =>
              setSubmitValid((prev) => {
                return { ...prev, validEmail: !!e.target.validity.valid };
              })
            }
          />
          <input
            className={S.input}
            name="msisdn"
            placeholder="o seu número de telefone"
            type="number"
            min={900000000}
            max={999999999}
            onChange={(e) =>
              setSubmitValid((prev) => {
                return { ...prev, validMsisdn: !!e.target.validity.valid };
              })
            }
          />
          <button className={S.button} disabled={isSubmitEnabled || isLoading}>
            {isLoading && <span className={S.loader}></span>}
            <span>{t("button_continue_title")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
